import '../polyfills';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import NavBar from '@/components/navbar';
import DefaultHead from '@/components/default-head';
import styled, { createGlobalStyle, css, ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import { NextIntlProvider, IntlErrorCode } from 'next-intl';
import { LiveChatLoaderProvider, HelpScout } from 'react-live-chat-loader';

import 'normalize.css/normalize.css';
import '@bidalihq/ui/dist/index.css';
import 'animate.css/animate.min.css';

import googleTagManagerHtml from '../google-tag-manager-html';
import config from '@/config';
import { StoreProvider } from '@/stores/store-provider';
import enabledCurrencies from '@data/enabled-currencies';
import theme, { media } from '@/themes';
import Bugsnag, { ErrorBoundary } from '@/bugsnag'
import { initializeTracking, trackPageView } from '../tracker';
import ErrorPage from './_error';

import Currencies from '@/components/currencies';
import Footer from '@/components/footer';

const Cart = dynamic(() => import('@/components/cart'), { ssr: false });
const countries = require('@data/countries.json');
const categories = require('@data/cats.json');

export const GlobalStyle = createGlobalStyle`
  :root {
    font-size: min(max(16px, 2vw), 18px);
    line-height: 1.25;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: ${props => props.theme.colors.background.primary};
    color: ${props => props.theme.colors.typography.primary};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }

  h1 {
    font-size: 2em;
    margin: .5em 0;

    ${media.sm`
      font-size: 1.75em;
    `};
  }

  h2 {
    font-size: 1.5em;
    margin: .5em 0;

    ${media.sm`
      font-size: 1.125em;
    `};
  }

  a {
    text-decoration: none;
  }

  p {
    line-height: 1.5;
    margin: 1em 0;
  }

  .toast {
    .toast-body {
      font-family: ${({ theme }) => theme.fonts.primary};
      font-weight: 400;
      font-size: 14px;
      color: white;
    }

    .primary-background {
      background: ${props => props.theme.colors.primary};
    }

    .error-background {
      background: #F44336;
    }
  }
`;

const AppContainer = styled.div`
  max-width: 100vw;
  position: relative;
`;

const MainContent = styled.div`
  padding-top: 4em;
  min-height: 100vh;

  .home & {
    padding-top: 0;
  }

  ${({ $hideHeader }) => $hideHeader && css`
    padding-top: 0;
  `};
`;


const MaintenanceBanner = styled.div`
  background: #ffdddd;
  padding: 1em;
  font-weight: 300;
  font-size: 0.9em;
  text-align: center;
`;

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();

  const isWindows = typeof window !== 'undefined'
    ? navigator.userAgent.toLowerCase().includes('windows')
    : false;

  const handleIntlError = error => {
    if (error.code !== IntlErrorCode.MISSING_MESSAGE) {
      Bugsnag.notify(error);
    }
  };

  const handleMessageFallback = ({ namespace, key, error }) => {
    if (process && process.env.NODE_ENV !== 'production') {
      return `[missing key] ${namespace}.${key}`;
    }

    if (error.code === IntlErrorCode.MISSING_MESSAGE) {
      Bugsnag.notify(new Error(`[missing key] ${namespace}.${key} - ${router.asPath} - ${router.pathname}`));
      return key;
    }
  };

  useEffect(() => {
    initializeTracking();
    const handleRouteChange = (url) => trackPageView(url);
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <LiveChatLoaderProvider providerKey="27d58209-595b-4353-b5f7-eb783f85a513" provider="helpScout">
        <NextIntlProvider messages={pageProps.messages} onError={handleIntlError} getMessageFallback={handleMessageFallback}>
          <Helmet bodyAttributes={{ class: isWindows ? 'use-custom-scrollbars' : '' }} />
          <DefaultHead />
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <StoreProvider pageProps={pageProps}>
              <AppContainer>
                {!pageProps.hideHeader && <NavBar pageProps={pageProps} />}
                <MainContent $hideHeader={pageProps.hideHeader}>
                  {config.inMaintenanceMode && <MaintenanceBanner>The majority of giftcards are not available and ordering is currently disabled while we are doing some maintenance and upgrades to our system.<br />We&apos;ll be back up shortly! You can contact us via <a href="mailto:support.spend@bidali.com">support.spend@bidali.com</a> and we can let you know when maintenance is complete.</MaintenanceBanner>}
                  <Component {...pageProps} currencies={enabledCurrencies} countries={countries} categories={categories} />
                </MainContent>
                {!pageProps.hideCurrencies && <Currencies currencies={enabledCurrencies} />}
                {!pageProps.hideFooter && <Footer />}
                <Cart />
                <Toaster />
              </AppContainer>
            </StoreProvider>
          </ThemeProvider>
          {config.gaTrackingId && <Script async src={`https://www.googletagmanager.com/gtag/js?id=${config.gaTrackingId}`} />}
          <Script id="google-tag-manager" type="text/javascript" dangerouslySetInnerHTML={{ __html: googleTagManagerHtml }} />
          <HelpScout />
        </NextIntlProvider>
      </LiveChatLoaderProvider>
    </ErrorBoundary>
  );
};

export default MyApp;
